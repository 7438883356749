// 情報再設定のためのダイアログ

<template lang="pug">
modal-base.create-book(
  title='ブックの情報の再設定',
  @clickClose='$closeModal(false)'
)
  .p-3

    validation-observer(ref='observer')
      form
        //- 名前は必須
        validation-provider(v-slot='{ errors, failed }', rules='required')
          .flex.items-center(:class='{ "failed": failed }')
            .left.w-24.text-right.mr-3
              | お名前
            .right.flex-grow
              input.input-text.block.w-full(
                v-model.trim='form.name'
                type='text'
              )
              .text-xs.mt-1.text-red-600 {{ errors[0] }}

        //- その他の属性値
        template(v-for='attr in attributeEntries || []')
          validation-provider(v-slot='{ errors, failed }', rules='required')
            .flex.items-center.mt-3(:class='{ "failed": failed }')
              .left.w-24.text-right.mr-3
                | {{ attr.name }}
              .right.flex-grow
                select.select.block.w-full(
                  v-model='form.attributes[attr.key]'
                )
                  option(:value='undefined') 選択してください...
                  option(v-for='item in attr.candidates', :key='item') {{ item }}
                .text-xs.mt-1.text-red-600 {{ errors[0] }}

  template(v-slot:footer)
    .mt-3.p-3.text-center
      button.btn.bg-orange-500.text-white.px-10(
        @click.prevent='clickChange'
      ) 変更
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'RestAttributes',

  components: {
    ModalBase
  },

  props: {
    attributeEntries: {
      type: Array,
      required: true
    },
    // 現在の各属性値
    userAttributes: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        name: null,
        attributes: {}
      }
    }
  },

  created () {
    this.form.name = this.userAttributes.name
    for (const item of this.attributeEntries) {
      this.form.attributes[item.key] = this.userAttributes[item.key]
    }
  },

  methods: {
    async clickChange () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        alert('入力に誤りがあります。')
        return
      }
      this.$closeModal(true, {
        name: this.form.name,
        ...this.form.attributes
      })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
