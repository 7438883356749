// ページ番号等の表示
// ページ・スプレッド遷移時に表示するために使う

<template lang="pug">
.page-indicator
  transition-group
    li.fixed.z-10.text-center.bg-gray-700.text-white.font-bold.rounded-lg.list-none.p-2(
      v-for='item in messages', :key='item.id'
    )
      | {{ item.message }}
</template>

<script>
export default {
  name: 'MessageBanner',

  props: {
    // 表示時間(ms)
    duration: {
      type: Number,
      default: 2000
    }
  },

  data () {
    return {
      nextId: 1,
      messages: []
    }
  },

  methods: {
    // メッセージを追加
    addMessage (message) {
      const id = this.nextId++
      // ユニークなIDとメッセージをペアで保存
      this.messages.push({
        id,
        message
      })
      // 表示時間分経過後削除する
      setTimeout(() => {
        const index = this.messages.findIndex
        if (index === -1) return
        this.messages.splice(index, 1)
      }, this.duration)
    }
  }
}
</script>

<style lang="sass" scoped>
.page-indicator
  li
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    -webkit-font-smoothing: antialised
    min-width: 10rem
    opacity: 0.95
    &.v-enter, &.v-leave-to
      opacity: 0
    &.v-enter-active, &.v-leave-active
      transition: all 0.5s ease
</style>
