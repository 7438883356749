// 背景色の選択モーダル

<template lang="pug">
modal-base.background-selector(
  title='背景色の選択',
  size='s',
  @clickClose='$closeModal(false)'
)
  .p-3

    ul.clearfix
      li.w-10.h-10.float-left.rounded-full.border-4.mr-1.mb-1.last__mr-0.cursor-pointer(
        v-for='item in colors',
        :style='{ backgroundColor: item }',
        @click='selectColor(item)'
      )
</template>

<script>
import ModalBase from './modal-base'

import colors from '../utils/colors'

export default {
  name: 'BackgroundSelector',

  components: {
    ModalBase
  },

  computed: {
    colors () {
      return colors.background
    }
  },

  methods: {
    selectColor (color) {
      this.$closeModal(true, {
        color
      })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
