// レイヤーテンプレートの変更

<template lang="pug">
modal-base.layer-template-selector(
  title='レイアウトの変更',
  @clickClose='$closeModal(false)'
)
  ul.clearfix.border-2.border-white
    li.float-left.w-1_3.sm__w-1_4.border-2.border-white.bg-gray-200.rounded-lg.p-1(
      v-for='template in templates'
    )
      .bg-fit.cursor-pointer(
        :style='`background-image: url("${template.url}")`',
        @click='clickTemplate(template)'
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalBase from './modal-base'

export default {
  name: 'LayerTemplateSelector',

  components: {
    ModalBase
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters({
      templates: 'editor/currentAvailableLayerTemplates'
    })
  },

  methods: {
    ...mapActions({
      getLayerTemplatesForCurrentSpread: 'editor/getLayerTemplatesForCurrentSpread'
    }),
    async init () {
      try {
        this.getLayerTemplatesForCurrentSpread()
      } catch (e) {
        // TODO: エラー処理
      }
    },
    // テンプレートが選択されてた
    clickTemplate (template) {
      this.$closeModal(true, {
        id: template.id,
        body: template.body
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.layer-template-selector
  ul
    li
      overflow: hidden
      > div
        padding-top: 100%
        transition: all .3s ease
        &:hover
          transform: scale(1.2)
</style>
