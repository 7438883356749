// 追加アイテムのセレクタ

<template lang="pug">
modal-base.add-item-selector(
  size='s',
  title='アイテムの追加',
  @clickClose='$closeModal(false)'
)
  .p-3.text-center
    button.btn.block.w-full.bg-green-600.text-white(
      @click='$closeModal(true, "stamp")'
    ) スタンプの追加
    button.btn.block.mt-3.w-full.bg-green-600.text-white(
      @click='$closeModal(true, "text")'
    ) 文字の追加
    button.btn.mt-3(
      @click='$closeModal(false)'
    ) 閉じる
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'AddItemSelector',

  components: {
    ModalBase
  }
}
</script>

<style lang="sass" scoped>

</style>
