// エディタの共通部分

<template lang="pug">
.editor-common.fixed.inset-0.flex.flex-col

  //- タイトルバー
  div
    p.bg-teal-800.text-white.text-sm.py-1.px-2.leading-none.text-center {{ headerMessage }}

  //- メニューバー
  div
    header-menu(
      :is-preview='isPreview',
      @clickMenuItem='clickMenuItem'
    )

  //- relativeにするのはsafariのバグ対策
  //- ref:https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent
  .flex-auto.main.relative
    router-view

  //- click時にpreventしておかないと、タッチデバイスにてズームされてしまう(iOS safari等)
  .go-prev.bg-teal-800.text-white.fixed.px-6.py-2.rounded-r-full.text-3xl.cursor-pointer.select-none(
    v-show='!isFirst',
    @click.prevent='goToPrev'
  )
    fa-icon(icon='arrow-left')
  .go-next.bg-teal-800.text-white.fixed.px-6.py-2.rounded-l-full.text-3xl.cursor-pointer.select-none(
    v-show='!isLast',
    @click.prevent='goToNext'
  )
    fa-icon(icon='arrow-right')

  //- スマホ用要素追加ボタン
  .add.sm__hidden.fixed.flex.w-16.h-16.bg-pink-500.items-center.justify-center.text-3xl.text-white.rounded-full.select-none(
    v-if='!isPreview'
    @click.prevent='clickPlus'
  )
    fa-icon(icon='plus')

  page-indicator(ref='indicator')

  side-menu(
    v-show='showMenu'
    :items='sideMenuItems',
    side='left',
    @close='showMenu = false',
    @clickItem='clickSideMenuItem'
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import HeaderMenu from './components/header-menu'
import PageIndicator from './components/page-indicator'
import SideMenu from '../../components/side-menu'
import StampSelectorModal from '../../modals/stamps-selector'
import AddTextModal from '../../modals/add-text'
import AlertMessageModal from '../../modals/alert-message'
import BackgroundSelectorModal from '../../modals/background-selector'
import LayerTemplateSelectorModal from '../../modals/layter-template-selector'
import AddItemSelectorModal from '../../modals/add-item-selector'
import TutorialForEditorModal from '../../modals/tutorial-for-editor'
import ResetAttributesModal from '../../modals/reset-attributes'

const sideMenuItems = {
  editor: [
    { title: 'マイページトップ', code: 'myhome' },
    { title: '使い方の説明', code: 'tutorial' },
    { title: '見開き表示/1ページ表示切替', code: 'viewmode' },
    { title: 'クラス名等再設定', code: 'reset' }
  ],
  preview: [
    { title: 'マイページトップ', code: 'myhome' }
  ]
}

export default {
  name: 'EditorCommon',

  components: {
    HeaderMenu,
    SideMenu,
    PageIndicator
  },

  data () {
    return {
      showMenu: false
    }
  },

  computed: {
    ...mapGetters({
      bookTemplateName: 'editor/bookTemplateName',
      isFirstInEditor: 'editor/currentPositionIsFirstInEditor',
      isLastInEditor: 'editor/currentPositionIsLastInEditor',
      isFirstInPreview: 'editor/currentPositionIsFirstInPreview',
      isLastInPreview: 'editor/currentPositionIsLastInPreview',
      topLeftPoint: 'editor/topLeftPoint',
      attributeEntries: 'editor/attributeEntries',
      userAttributes: 'editor/userAttributes',
      currentSpreadIndex: 'editor/currentSpreadIndex',
      currentPageIndex: 'editor/currentPageIndex',
      viewPortInEditor: 'editor/viewPortInEditor',
      viewPortInPreview: 'editor/viewPortInPreview',
      // 未保存かどうかのフラグ
      isDirty: 'editor/isDirty'
    }),
    // 最上部のメッセージ
    headerMessage () {
      return `${this.bookTemplateName} (${this.isDirty ? '未保存' : '保存済'})`
    },
    bookId () {
      return parseInt(this.$route.params.bookId, 10)
    },
    // プレビューかどうか
    isPreview () {
      return this.$route.name === 'EditorPreview'
    },
    sideMenuItems () {
      return this.isPreview ? sideMenuItems.preview : sideMenuItems.editor
    },
    isFirst () {
      return this.isPreview ? this.isFirstInPreview : this.isFirstInEditor
    },
    isLast () {
      return this.isPreview ? this.isLastInPreview : this.isLastInEditor
    },
    // 現在のスプレッドまたはページ名
    currentSpreadOrPageName () {
      if (this.currentPageIndex === 0) return '表紙'
      else if (this.currentPageIndex === 1) return '背表紙'
      const viewPort = this.isPreview ? this.viewPortInPreview : this.viewPortInEditor
      if (viewPort === 'both') {
        const i = this.currentSpreadIndex
        return `${i * 2 - 1} 〜 ${i * 2}ページ目`
      } else {
        return `${this.currentPageIndex - 1}ページ目`
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      initEditorStore: 'editor/init',
      setError: 'app/setError',
      _goToNext: 'editor/goToNext',
      _goToPrev: 'editor/goToPrev',
      toggleShowEntireSpreadOrNotInPreview: 'editor/toggleShowEntireSpreadOrNotInPreview',
      pushHistory: 'editor/pushHistory',
      popHistory: 'editor/popHistory',
      addUserContent: 'editor/addUserContent',
      selectContent: 'editor/selectContent',
      updatecurrentSpreadBackground: 'editor/updatecurrentSpreadBackground',
      changeLayerTemplate: 'editor/changeLayerTemplate',
      _saveBook: 'editor/saveBook',
      saveAndAddToCart: 'editor/saveAndAddToCart',
      toggleShowEntireSpreadOrNotInEditor: 'editor/toggleShowEntireSpreadOrNotInEditor',
      forceUpdateBookAttributes: 'editor/forceUpdateBookAttributes'
    }),

    async init () {
      try {
        await this.initEditorStore({ bookId: this.bookId })
      } catch (e) {
        this.setError(e)
      }
    },

    clickMenuItem (code) {
      if (code === 'preview') {
        this.$router.push({ name: 'EditorPreview' })
      } else if (code === 'edit') {
        this.$router.push({ name: 'EditorMain' })
      } else if (code === 'viewport') {
        // 今の所、このメニューアイテムはプレビューにしかない
        this.toggleShowEntireSpreadOrNotInPreview()
      } else if (code === 'menu') {
        this.showMenu = true
      } else if (code === 'stamp') {
        this.showStampSelectorModal()
      } else if (code === 'text') {
        this.showAddTextModal()
      } else if (code === 'bg') {
        this.showBackgroundSelectorModal()
      } else if (code === 'layout') {
        this.showLayerTemplateSelectorModal()
      } else if (code === 'save') {
        this.saveBook()
      } else if (code === 'undo') {
        this.undo()
      } else if (code === 'cart') {
        this.goToCart()
      }
    },

    clickSideMenuItem (code) {
      this.showMenu = false
      if (code === 'myhome') {
        this.$router.push({ name: 'MyHome' })
      } else if (code === 'tutorial') {
        this.showTutorialModal()
      } else if (code === 'viewmode') {
        // 現状、このメニューが表示されるのはエディタ画面だけ
        this.toggleShowEntireSpreadOrNotInEditor()
      } else if (code === 'reset') {
        this.showResetAttributesModal()
      }
    },

    goToPrev () {
      this._goToPrev(this.isPreview)
      this.$refs.indicator.addMessage(this.currentSpreadOrPageName)
    },

    goToNext () {
      this._goToNext(this.isPreview)
      this.$refs.indicator.addMessage(this.currentSpreadOrPageName)
    },

    // スタンプ選択モーダルの表示
    async showStampSelectorModal () {
      const result = await this.$showModal(
        StampSelectorModal,
        true
      )
      if (!result.ok) return
      // ヒストリにプッシュ
      this.pushHistory()
      // 実際に追加
      const contentId = await this.addUserContent({
        ...result.obj,
        type: 'art',
        x: this.topLeftPoint.x + 10,
        y: this.topLeftPoint.y + 10,
        width: 30,
        height: 30,
        movable: true
      })
      this.selectContent(contentId)
    },

    // テキスト追加モーダルの表示
    async showAddTextModal () {
      const result = await this.$showModal(
        AddTextModal,
        true
      )
      if (!result.ok) return
      // ヒストリにプッシュ
      this.pushHistory()
      // 実際に追加
      const contentId = await this.addUserContent({
        ...result.obj,
        type: 'text',
        x: this.topLeftPoint.x + 10,
        y: this.topLeftPoint.y + 10,
        width: 8 * result.obj.value.length,
        height: 8,
        movable: true
      })
      this.selectContent(contentId)
    },

    // 背景色選択モーダル
    async showBackgroundSelectorModal () {
      const result = await this.$showModal(
        BackgroundSelectorModal,
        true
      )
      if (!result.ok) return
      // ヒストリにプッシュ
      this.pushHistory()
      // 実際に更新
      this.updatecurrentSpreadBackground({
        type: 'fill',
        color: result.obj.color,
        changeable: true
      })
    },

    // レイアウトの選択モーダル
    async showLayerTemplateSelectorModal () {
      const result = await this.$showModal(
        LayerTemplateSelectorModal,
        true
      )
      if (!result.ok) return
      // ヒストリにプッシュ
      this.pushHistory()
      // 実際に変更
      this.changeLayerTemplate({
        templateId: result.obj.id,
        contents: result.obj.body
      })
    },

    // 属性値リセット用モーダル
    async showResetAttributesModal () {
      const result = await this.$showModal(
        ResetAttributesModal,
        false,
        {
          attributeEntries: this.attributeEntries || [],
          userAttributes: this.userAttributes
        }
      )
      if (!result.ok) return
      try {
        await this.forceUpdateBookAttributes(result.obj)
      } catch (e) {
        this.setError(e)
      }
    },

    // ブックの内容の保存
    async saveBook () {
      try {
        await this._saveBook()
        this.$showModal(AlertMessageModal, true, {
          title: '保存完了',
          message: '保存しました。'
        })
      } catch (e) {
        this.setError(e)
      }
    },

    // アンドゥする
    undo () {
      this.popHistory()
    },

    async clickPlus () {
      const result = await this.$showModal(AddItemSelectorModal, true)
      if (!result.ok) return
      if (result.obj === 'stamp') {
        this.showStampSelectorModal()
      } else if (result.obj === 'text') {
        this.showAddTextModal()
      }
    },

    // カートへ
    async goToCart () {
      try {
        // まず保存
        await this.saveAndAddToCart()
        // 次に遷移
        this.$router.push({ name: 'OrderCart' })
      } catch (e) {
        this.setError(e)
      }
    },

    // チュートリアルモーダルの表示
    showTutorialModal () {
      this.$showModal(
        TutorialForEditorModal,
        true
      )
    }
  },

  // ブラウザバックした場合、保存して遷移
  async beforeRouteLeave (to, from, next) {
    await this._saveBook()
    next()
  }
}
</script>

<style lang="sass" scoped>
.editor-common
  background: linear-gradient(#F1F7F8, #B6D1D5)
  .go-prev
    left: 0
    bottom: 3rem
  .go-next
    right: 0
    bottom: 3rem
  .add
    bottom: 1rem
    left: 50%
    margin-left: -2rem
</style>
