// スタンプのセレクタ

<template lang="pug">
modal-base.stamp-selector(
  title='スタンプの選択',
  @clickClose='$closeModal(false)'
)
  ul.clearfix.border-2.border-white
    li.float-left.w-1_3.sm__w-1_4.border-2.border-white.bg-gray-300.rounded-lg(
      v-for='stamp in stamps || []'
    )
      .bg-fit.cursor-pointer(
        :style='`background-image: url("${stamp.small}")`',
        @click='clickStamp(stamp)'
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalBase from './modal-base'

export default {
  name: 'StampSelector',

  components: {
    ModalBase
  },

  computed: {
    ...mapGetters({
      stamps: 'editor/stamps'
    })
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      getStamps: 'editor/getStamps',
      setError: 'app/setError'
    }),
    // 初期化
    async init () {
      try {
        await this.getStamps()
      } catch (e) {
        this.setError(e)
      }
    },
    // スタンプがクリックされた
    clickStamp (stamp) {
      this.$closeModal(true, {
        url: stamp.large
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.stamp-selector
  ul
    li
      overflow: hidden
      > div
        padding-top: 100%
        transition: all .5s ease
        &:hover
          transform: scale(1.2)
</style>
