// テキスト追加モーダル

<template lang="pug">
modal-base.add-text(
  title='文字の追加',
  @clickClose='$closeModal(false)'
)
  .p-3

    .text
      input.input-text.w-full.text-lg.py-2(
        type='text',
        placeholder='文字を入力...',
        v-model.trim='text'
      )

    //- 文字の色
    .colors.sm__flex.mt-4.items-center
      .mr-3.flex-shrink-0 文字の色:
      ul.clearfix
        li.w-8.h-8.float-left.rounded-full.border-4.ml-1.first__ml-0.cursor-pointer(
          v-for='item in colors',
          :style='{ backgroundColor: item }',
          :class='{ "border-red-300": item === selectedColor }',
          @click='selectedColor = item'
        )

    //- フォント
    .font.sm__flex.mt-4.items-center
      .mr-3.flex-shrink-0 書体:
      div.flex-grow
        select.select.block.w-full(
          v-model='selectedFont'
        )
          option(
            v-for='font in fonts'
            :value='font.code'
          ) {{ font.name }}

  template(v-slot:footer)
    .mt-1.p-3.text-center
      button.btn.bg-orange-500.text-white.px-10(
        :disabled='!isValid ? "disabled" : null',
        @click='clickOk'
      )
        span.ml-1 OK
      button.btn.ml-2(
        @click='$closeModal(false)'
      ) キャンセル
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalBase from './modal-base'
import colors from '../utils/colors'

export default {
  name: 'AddText',

  components: {
    ModalBase
  },

  data () {
    return {
      text: '',
      selectedColor: null,
      selectedFont: null
    }
  },

  computed: {
    ...mapGetters({
      fonts: 'editor/availableFonts'
    }),
    colors () { return colors.text },
    // 文字列追加可能な状態か
    isValid () {
      return this.text && this.selectedColor && this.selectedFont
    }
  },

  mounted () {
    this.selectedColor = this.colors[this.colors.length - 1]
    this.selectedFont = this.fonts[0].code
  },

  methods: {
    ...mapActions({
      getTextRendererInfo: 'editor/getTextRendererInfo'
    }),

    clickOk () {
      this.$closeModal(true, {
        value: this.text,
        fill: this.selectedColor,
        font: this.selectedFont
      })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
